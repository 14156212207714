<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <form
      class="form-horizontal"
      @submit.prevent="adding"
    >
      <div
        class=" alert alert-danger"
        v-if="has_error"
      >
        <p>{{ error_msg }}</p>
      </div>
      <div class="form-group">
        <animal-detail :show-detail="true" />
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="dateOperation">Date de la demande</label>
            <datepicker
              id="dateOperation"
              name="dateOperation"
              input-class="form-control required"
              :disabled-dates="disabledOperationDate"
              format="dd/MM/yyyy"
              placeholder="dd/mm/yyyy"
              v-model="operationDate"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div :class="showCategorie? 'col-md-6':'col-md-12'">
          <div class="form-group">
            <label for="type">Type d'Analyse</label>
            <select
              class="form-control"
              name="type"
              id="type"
              v-select="{placeholder: 'Selectionnez le type d\'analyse'}"
              v-model="type"
            >
              <option value="" />
              <option
                v-for="(t, i) in types"
                :value="t.uid"
                :key="i"
              >
                {{ t.libelle }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="col-md-6"
          v-show="showCategorie"
        >
          <div class="form-group">
            <label for="categorie">Categorie</label>
            <select
              class="form-control"
              name="categorie"
              id="categorie"
              v-select="{placeholder: 'Selectionnez la catégorie d\'analyse'}"
              v-model="categorie"
            >
              <option value="" />
              <option
                v-for="(c, i) in filterdCategories"
                :value="c.uid"
                :key="i"
              >
                {{ c.libelle }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        class="row"
        v-show="showCategorie && showTypeRecherche"
      >
        <div :class="showExamen? 'col-md-6':'col-md-12'">
          <div class="form-group">
            <label for="type-recherche">Type de recherche</label>
            <select
              class="form-control"
              name="type-recherche"
              id="type-recherche"
              v-select="{placeholder: 'Selectionnez le type de recherche'}"
              v-model="typeRecherche"
            >
              <option value="" />
              <option
                v-for="(t, i) in filterTypeRecherches"
                :value="t.uid"
                :key="i"
              >
                {{ t.libelle }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="col-md-6"
          v-show="showExamen"
        >
          <div class="form-group">
            <label for="examen">Examen</label>
            <select
              class="form-control"
              name="examen"
              id="examen"
              v-select="{placeholder: 'Selectionnez l\'examen'}"
              v-model="examen"
            >
              <option value="" />
              <option
                v-for="(e, i) in filterExamens"
                :value="e.uid"
                :key="i"
              >
                {{ e.libelle }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="info">Information Supplementaire</label>
            <textarea
              class="form-control"
              name="infp"
              id="info"
              rows="5"
              v-model="infoSupplementaire"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <button
            type="submit"
            class="btn btn-primary"
            :class="loading? 'disabled': ''"
          >
            <span
              class="spinner-border m-1"
              role="status"
              v-if="loading"
            /> 
            <span v-else> Demander </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import Datepicker from 'vuejs-datepicker'
import navbar from '../../../components/navbar.vue'
import AnimalDetail from '../../../components/identification/animal/detailInform.vue'
import { Select } from '../../../directive/select'
import {ADD_ANALYSE} from '../../../graphql/sanitaire'
export default {
    components: {navbar, AnimalDetail, Datepicker},
    directives: {Select},
    data(){
        return {
          navbarItems: [
                {
                    libelle: 'Sanitaire'
                },
                {
                    libelle: 'Examen para-clinique',
                    route: 'analyses'
                },
                {
                  libelle: 'Demande d\'analyse'
                }
                ],
                pageIcon: 'la-flask',
                pageTitle: 'Demande d\'analyse',
                pageDescription: 'Ajouter une demande d\'analyse', 
            type: null,
            categorie: null,
            typeRecherche: null,
            examen: null,
            infoSupplementaire: null,
            operationDate: new Date(),
            has_error:false,
            error_msg: null,
        }
    },
    mounted(){
      this.initForm()
    },
    methods: {
        ...mapMutations({
          setLoading: 'SET_LOADING'
        }),
        initForm(){
          this.setLoading(false)
          this.type =  null
          this.categorie =  null
          this.typeRecherche =  null
          this.examen =  null
          this.infoSupplementaire =  null
          this.operationDate = new Date()
          this.has_error = false
          this.error_msg =  null
        },
        adding(){
            this.setLoading(true)
            let data = {
                animal: this.selectedAnimal,
                type: this.type,
                categorie: this.categorie,
                typeRecherche: this.typeRecherche,
                examen: this.examen,
                infoSupplementaire: this.infoSupplementaire,
                operationDate: this.operationDate
              }
              this.$apollo.mutate({
                mutation: ADD_ANALYSE,
                variables: {
                    "analyse": {
                        ...data
                    }
                },
                update: (data) => {
                    console.log(`Analyse add successfully on uid ${data.uid}`)
                }
              }).then(() => {
                this.initForm()
                this.$router.push({name: 'analyses'})
            }).catch((error) => {
                this.setLoading(false)
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            types: 'sanitaire/typeAnalyses',
            categories: 'sanitaire/categorieAnalyses',
            typeRecherches: 'sanitaire/typeRecherches',
            examens: 'sanitaire/examens',
            selectedAnimal: 'identification/selectedAnimal',
            animaux: 'identification/animaux',
            loading: 'loading'
        }),
        disabledOperationDate () {
            let animal = [...this.animaux].find(item => item.uid === this.selectedAnimal)
            if(animal !== null && animal !== undefined){
                return {
                    to: new Date(animal.dateNaissance),
                    from: new Date()
                }
            }
            return {
                to: new Date(),
                from: new Date()
            }
        },
        showCategorie(){
            if(this.type !== null){
                let type = this.types.find(item => item.uid === this.type)
                return type.hasCategorie
            }
            return false
        },
        showTypeRecherche(){
            if(this.categorie !== null){
                let categorie = this.categories.find(item => item.uid === this.categorie)
                return categorie.hasRecherche
            }
            return false
        },
        showExamen(){
          if(this.typeRecherche !== null){
            let type = this.typeRecherches.find(item => item.uid === this.typeRecherche)
            return type.hasExamen
          }
          return false
        },
        filterdCategories(){
            if(this.type !== null) return [...this.categories].filter(item => item.typeAnalyse === this.type)
            return []
        },
        filterTypeRecherches(){
            if(this.categorie !== null) return [...this.typeRecherches].filter(item => item.categorieAnalyse === this.categorie)
            return []
        },
        filterExamens(){
            if(this.typeRecherche !== null) return [...this.examens].filter(item => item.typeRecherche === this.typeRecherche)
            return []
        }
    }
}
</script>

<style>

</style>